/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css"; /*Imported for Material, can be overwritten*/
@import "~@fortawesome/fontawesome-free/css/all.css";
@import './theme.scss';


.cursor-pointer{
cursor: pointer;
}

body {
    background: #F2F4F4 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #707070
}

.autoMargins {
    margin-left: auto;
    margin-right: auto;
  }


.dashboard-card {
    background-color:#005984;
    box-shadow: 0px 2px 4px #00000029;
    padding-bottom: 10px;
    height: 175px;
    width: 100%;
    color: white;
    .title {
        text-align: center;
        width: 100%;
        color: white;
        font-size: 1.3em;
        font-weight: 600;
        font-family: "NewsGothicBT-Roman", Arial, sans-serif;
    }
}

.dashboard-card-alerts {
    background-color: white;
    box-shadow: 0px 2px 4px #00000029;
    padding-bottom: 10px;
    height: 100%;
    width: 100%;
    .title {
        text-align: center;
        width: 100%;
        font-size: 1.3em;
        font-weight: 600;
        font-family: "NewsGothicBT-Roman", Arial, sans-serif;
    }
}

.outlined {
    border: 1px solid #707070;
    border-radius: 5px;
}

.background-white {
    background-color: white;
  }

  .snackbar {
    max-width: 90% !important;
    margin-left: auto !important; // center align from leftmargin-right: auto !important; // center align from rightmargin-bottom: 1rem !important; 
    padding: 10px !important; // spacing between the text and boundarybackground-color: green;
    color: red;
    background-color: lightgray;
}