@font-face {
    font-family: 'NewsGothicBT-Roman';
    src: url('../../../assets/fonts/NewsGothBTRoman.ttf') format("truetype");
  }
  
  @font-face {
    font-family: 'NewsGoth Lt BT Light Italic';
    src: url('../../../assets/fonts/NewsGoth Lt BT Light Italic.ttf') format("truetype");
  }
  
  @font-face {
    font-family: 'NewsGoth BT Light';
    src: url('../../../assets/fonts/NewsGoth BT Light.ttf') format("truetype");
  }